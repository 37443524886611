import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

class WorkNav extends Component {
    render() {
        return (
            <Container fluid className="work-nav-container background-brown">

                <Helmet>
                    <title>deliadrel | work</title>
                    <meta name="description" content="Work projects of deliadrel" />
                    <meta name="keywords" content="coding, app, graphic design, logo design, wireframe, layout" />
                </Helmet>

                <Row className="my-work-row">
                    <Col xs={12} md={6} >
                        <div className="work-nav text-center my-4">
                            <h2><a className="work-link"  href="workcoding">coding.</a></h2>
                        </div>      
                    </Col>
                    <Col xs={12} md={6} >
                        <div className="work-nav text-center my-4">
                            <h2><a className="work-link"  href="workdesign">design.</a></h2>
                        </div>      
                    </Col>
                </Row>  
        </Container>    
        )
    }
}

export default WorkNav;