import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Home extends Component {
    render() {
        return (
            <Container fluid className="home-container pt-5" style={{backgroundColor:" #C7CECA"}}>
                <Row className="mx-3">
                    <Col xs={4} md={2} lg={2} className="blob-container">
                        <div className="blob"></div>  
                        <p className="intro-text-1 text-right">Hello.<br></br>I am Delia.</p>
                    </Col>
                    <Col xs={8} md={5} lg={4} className="pl-2">
                        <p className="intro-text-2 text-left">I am a creative web developer with a background 
                        in fashion and graphic design. I believe that functionality and design are inseparable from each other. Let's make things that last.</p>
                    </Col>
                    <Col xs={0} md={5} lg={6}></Col>
                </Row>  
            </Container>    
        )
    }
}

export default Home;