import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';


class Contact extends Component {
    render() {
        return (
            <Container fluid className="contact-container background-brown">

                <Helmet>
                    <title>deliadrel | contact</title>
                    <meta name="description" content="Contact details of deliadrel" />
                    <meta name="keywords" content="email, linkedIn" />
                </Helmet>

                <Row>
                    <Col xs={12} md={7} lg={4}>
                        <div className="contact-text text-left">
                            <p>If you'd like to talk, exchange ideas or propose a project, feel free to get in touch.</p>
                            <ul className="contact-list">
                                <li><a className="contact-me" target="_blank" rel="noreferrer" href="mailto:d.drel@hotmail.com">| Email</a></li>
                                <li><a className="contact-me" target="_blank" rel="noreferrer" href="http://linkedin.com/in/delia-drel-2b55ba7b">| LinkedIn</a></li>
                            </ul>        
                        </div>      
                    </Col>
                </Row>  
            </Container>    
        )
    }
}

export default Contact;