import React, { Component } from 'react';
import { Navbar, Nav, Container} from 'react-bootstrap';

 class NavBar extends Component {
    render() {
        return (
            <>
                <Container fluid>
                    <Navbar id="nav-container" collapseOnSelect fixed="top pt-3" expand="md" variant="dark">    
                        <Navbar.Brand href="/">
                        <img className="nav-logo" src="../image/logo.png" alt="delia drel logo"/>
                        </Navbar.Brand>
                        <Navbar.Toggle className="nav-hamburger mr-1" aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto nav-container">
                                <Nav.Link className="nav-links py-0 mt-1" href="/work">work.</Nav.Link>
                                <Nav.Link className="nav-links py-0 mt-1" href="/about">about.</Nav.Link>
                                <Nav.Link className="nav-links py-0 mt-1" href="/contact">contact.</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
             </>
         )
    }
}

export default NavBar;