import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';


class About extends Component {
    render() {
        return (
            <Container fluid className="about-container background-brown">

                <Helmet>
                    <title>deliadrel | about</title>
                    <meta name="description" content="Some info about deliadrel" />
                    <meta name="keywords" content="info, deliadrel, skills" />
                </Helmet>
            
                <Row>
                    <Col xs={12} md={7} lg={5}>
                        <div className="about-text text-left">
                            <h1>Hi.</h1>
                            <p>I'm Delia, a creative front-end developer with a background in fashion and graphic design. 
                            I was born in Moscow but moved to Amsterdam in my early teenage. </p>
                            <p>I'm interested in the combination of design and technology. And as a designer, I have a strong belief 
                            that functionality and design are inseparable from each other.</p>
                            <p> I have an open-mind, get a kick out of learning new things and love to travel around the world without a plan.</p>
                            <ul className="my-skills-list">
                                <li className="my-skills">HTML5 </li>
                                <li className="my-skills">| CSS3 </li>
                                <li className="my-skills">| JavaScript </li>
                                <li className="my-skills">| React </li>
                                <li className="my-skills">| Nodejs </li>
                                <li className="my-skills">| Express </li>
                                <li className="my-skills">| Bootstrap</li>
                            </ul>
                        </div>      
                    </Col>
                </Row>  
            </Container>    
        )
    }
}

export default About;