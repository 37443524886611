import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col} from 'react-bootstrap';


class WorkCoding extends Component {
    render() {
        return ( 
          <Container fluid className="work-container work-coding pt-5">

                <Helmet>
                    <title>deliadrel | work coding</title>
                    <meta name="description" content="Coding projects of deliadrel" />
                </Helmet>

            <Row className='my-row my-card-row'>
               {/*  COLUMN 1 - CONTENT WORK */}
               <Col xs={12} id="my-card-container" >
                    <div className="card my-card px-4">
                        <div className="card-body">
                            <h1 className="card-title text-uppercase py-3">portfolio</h1>
                            <p className="card-text">A responsive single page app with an overview of selected projects.</p>
                            <footer>
                                <ul className="my-tag-list">
                                    <li className="small my-tags">React </li> 
                                    <li className="small my-tags">| JavaScript </li>
                                    <li className="small my-tags">| HTML5 </li>
                                    <li className="small my-tags">| CSS3 </li>
                                    <li className="small my-tags">| Bootstrap</li>
                                    <li className="small my-tags">| React-Bootstrap </li>      
                                </ul>
                            </footer>
                            <a target="_blank" rel="noreferrer" className="my-project-link" href="https://github.com/deframed/work-overview">
                            <p className="project-btn">GitHub</p>
                            </a> 
                        </div>  
                    </div>
                    <img className="card-img-bottom my-img" src="../image/site.png" alt="Card image"></img>
                </Col>
                {/*  COLUMN 2 - CONTENT WORK */}
                 <Col xs={12} id="my-card-container" >
                    <div className="card my-card px-4">
                        <div className="card-body">
                            <h1 className="card-title text-uppercase py-3">storykeeper</h1>
                            <p className="card-text">"Storykeeper" is a Full Stack single page app where you can share your travel stories. 
                            <br></br>After signing up, you can post stories and review & like stories of other travelers.</p>
                            <footer>
                                <ul className="my-tag-list">
                                    <li className="small my-tags">React </li> 
                                    <li className="small my-tags">| JavaScript </li>
                                    <li className="small my-tags">| HTML5 </li>
                                    <li className="small my-tags">| CSS3 </li>
                                    <li className="small my-tags">| Nodejs </li>
                                    <li className="small my-tags">| MongoDB </li>
                                    <li className="small my-tags">| Bootstrap</li>
                                </ul>
                            </footer>
                            <a target="_blank" rel="noreferrer" className="my-project-link" href="https://story--keeper.herokuapp.com/">
                            <p className="project-btn">visit</p>
                            </a>
                             
                        </div>  
                    </div>
                    <img className="card-img-bottom my-img" src="../image/storyKeeper.png" alt="Card image"></img>
                </Col>
                {/*  COLUMN 3 - CONTENT WORK */}
                <Col xs={12} id="my-card-container" >
                    <div className="card my-card px-4">
                        <div className="card-body">
                            <h1 className="card-title text-uppercase py-3">pet and bed</h1>
                            <p className="card-text">"Pet and bed" is a Full Stack app for people who love pets and new experiences. 
                            <br></br>After signing up as a "pet lover", you can connect with people and travel to another city where you'll 
                            get accommodation in exchange for taking care of your host's pet. As a "pet owner", you can enjoy your holiday 
                            knowing that your four-legged friend gets all the love and attention while you’re away.</p>
                            <footer>
                                <ul className="my-tag-list">
                                    <li className="small my-tags">JavaScript </li>
                                    <li className="small my-tags">| Handlebars </li>
                                    <li className="small my-tags">| Nodejs </li>
                                    <li className="small my-tags">| Express</li>
                                    <li className="small my-tags">| HTML5 </li>
                                    <li className="small my-tags">| CSS3 </li>
                                    <li className="small my-tags">| MongoDB </li>
                                    <li className="small my-tags">| Bootstrap</li>
                                </ul>
                            </footer>
                            {/* <a target="_blank" rel="noreferrer" className="my-project-link" href="#">
                            <p className="project-btn">visit</p>
                            </a> */}
                         </div>
                    </div>
                    <img className="card-img-bottom my-img" src="../image/PetAndBed_soon.png" alt="Card image"></img>    
                </Col>
                {/*  COLUMN 4 - CONTENT WORK */}              
                <Col xs={12} id="my-card-container" >
                    <div className="card my-card px-4">
                        <div className="card-body">
                            <h1 className="card-title text-uppercase py-3">pelicans gotta eat too</h1>
                            <p className="card-text">"Pelicans gotta eat too" is a simple children's game built after two weeks of studying JavaScript. 
                            The player can move the pelican from left to right and help this little creature to catch some starts and satisfy its hunger. 
                            One missed start will end the game so the player can start all over again.</p>
                            <footer>
                                <ul className="my-tag-list">
                                    <li className="small my-tags">JavaScript </li>
                                    <li className="small my-tags">| Canvas </li>
                                    <li className="small my-tags">| HTML5 </li>
                                    <li className="small my-tags">| CSS3 </li>
                                </ul>
                            </footer> 
                            <a target="_blank" rel="noreferrer" className="my-project-link" href="https://deframed.github.io/pelicans-gotta-eat-too/">
                            <p className="project-btn">visit</p>
                            </a>  
                        </div>      
                    </div> 
                    <img className="card-img-bottom my-img" src="../image/PGET_white.png" alt="Card image"></img>
              </Col>
           </Row>
        </Container>  

        )
    }
}
export default WorkCoding;
