import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

class WorkDesign extends Component {
    render() {
        return (
            <Container fluid className="work-container pt-5">

                <Helmet>
                    <title>deliadrel | work design</title>
                    <meta name="description" content="Design projects of deliadrel" />
                    <meta name="keywords" content="graphic design, logo design, wireframe, layout" />
                </Helmet>

                <Row className='my-row my-design-card-row'>
                    {/*  COLUMN 1 - CONTENT WORK */}
                    <Col xs={12} id="my-card-container">
                        <div className="card my-design-card  px-4">
                            <div className="card-body">
                                <h1 className="card-title text-uppercase py-3">cooking app</h1>
                                <p className="card-text">Wireframe for a mobile cooking app. The app has three themes: vegetarian, fish and dessert. 
                                You can go through all recipes or apply a filter, adding recipes to your favorites and make an ingredient list. All recipes 
                                have a clear step-by-step description.</p>
                                <footer>
                                    <ul className="my-tag-list">
                                        <li className="small my-tags">Adobe CC </li> 
                                    </ul>
                                </footer>   
                            </div>      
                        </div>
                        <Carousel className="my-carousel">
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/wireframe_1.png"
                                alt="First slide"
                                />  
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/wireframe_2.png"
                                alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/wireframe_3.png"
                                alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    {/*  COLUMN 2 - CONTENT WORK */}
                    <Col xs={12} id="my-card-container">
                        <div className="card my-design-card px-4">
                            <div className="card-body">
                                <h1 className="card-title text-uppercase py-3">brand identity</h1>
                                <p className="card-text">New brand identity for a personal trainer company: logo design, print advertising materials, merchendise.</p>
                                <footer>
                                    <ul className="my-tag-list">
                                        <li className="small my-tags">Adobe CC </li> 
                                    </ul>
                                </footer>   
                            </div>      
                        </div>
                        <Carousel className="my-carousel">
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/mti_1.png"
                                alt="First slide"
                                />  
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/mti_2.png"
                                alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    {/*  COLUMN 3 - CONTENT WORK */}              
                    <Col xs={12} id="my-card-container">
                        <div className="card my-design-card  px-4">
                            <div className="card-body">
                                <h1 className="card-title text-uppercase py-3">styling card</h1>
                                <p className="card-text">Layout template and styling outfits.</p>
                                <footer>
                                    <ul className="my-tag-list">
                                        <li className="small my-tags">Adobe CC </li> 
                                    </ul>
                                </footer>   
                            </div>      
                        </div>
                        <Carousel className="my-carousel">
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/layout-1.png"
                                alt="First slide"
                                />  
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/layout-2.png"
                                alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/layout-3.png"
                                alt="First slide"
                                />  
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="../image/layout-4.png"
                                alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
        </Container>  
           
        )
    }
}

export default WorkDesign;