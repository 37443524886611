import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

class NotFound extends Component {
    render() {
        return (
            <Container fluid className="not-found-container background-brown">

                <Helmet>
                    <title>deliadrel | page not found</title>
                </Helmet>

                <Row>
                    <Col xs={12} >
                        <div className="text-center">
                            <h2 className="not-found-text">not found.</h2> 
                        </div>      
                    </Col>
                </Row>  
            </Container>    
        )
    }
}

export default NotFound;