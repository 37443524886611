import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// import components
import NavBar from './components/NavBar';
import Home from './components/Home';
import WorkNav from './components/WorkNav';
import WorkCoding from './components/WorkCoding';
import WorkDesign from './components/WorkDesign';
import About from './components/About';
import Contact from './components/Contact';
import NotFound from './components/NotFound';

// import styling
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container, Row } from 'react-bootstrap';


class App extends Component {
  render() {
    return (
      <div className="background-grey">
        <Container fluid className="root-container mx-0 px-0">    
          <Row>
            <NavBar />
          </Row>
          <Row className="content-row mx-0 px-0">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/work" component={WorkNav} />
                <Route exact path="/workcoding" component={WorkCoding} />
                <Route exact path="/workdesign" component={WorkDesign} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route render={() => {return <NotFound />}} />
              </Switch>
            </Row>
        </Container>  
      </div>
    )
  }
}

export default App;